import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  languagePrefix?: string | null
  title: string
  URL: string
}

export const Item = memo(function Item({
  image,
  languagePrefix,
  title,
  URL,
}: Props) {
  if (!title && !URL) {
    return null
  }

  const componentDOM = (
    <>
      {image ? <Image {...image} /> : null}
      <Title>{title}</Title>
    </>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return externalURL ? (
    <ExtLink href={URL} rel="noopener" target="_blank">
      {componentDOM}
    </ExtLink>
  ) : (
    <Anchor to={languagePrefix ? `/${languagePrefix}/${URL}` : `/${URL}`}>
      {componentDOM}
    </Anchor>
  )
})

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62vh;
  max-height: 35rem;
  overflow: hidden;
  padding: 0 3.333vw;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
    h2 {
      &:after {
        right: 0;
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 767px) {
    height: 72.5vh;
    max-height: 18.75rem;
  }
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.4375rem;
  font-weight: 600;
  line-height: 3.4375rem;
  position: relative;
  z-index: 2;
  text-transform: uppercase;

  &:after {
    content: '';
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.625rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`
